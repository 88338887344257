exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-lab-grown-diamonds-js": () => import("./../../../src/pages/about/lab-grown-diamonds.js" /* webpackChunkName: "component---src-pages-about-lab-grown-diamonds-js" */),
  "component---src-pages-about-traceability-js": () => import("./../../../src/pages/about/traceability.js" /* webpackChunkName: "component---src-pages-about-traceability-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-bespoke-engagement-ring-js": () => import("./../../../src/pages/bespoke-engagement-ring.js" /* webpackChunkName: "component---src-pages-bespoke-engagement-ring-js" */),
  "component---src-pages-bespoke-landing-js": () => import("./../../../src/pages/bespoke-landing.js" /* webpackChunkName: "component---src-pages-bespoke-landing-js" */),
  "component---src-pages-bespoke-service-js": () => import("./../../../src/pages/bespoke-service.js" /* webpackChunkName: "component---src-pages-bespoke-service-js" */),
  "component---src-pages-brussels-store-js": () => import("./../../../src/pages/brussels-store.js" /* webpackChunkName: "component---src-pages-brussels-store-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dragons-den-exclusive-offer-js": () => import("./../../../src/pages/dragons-den-exclusive-offer.js" /* webpackChunkName: "component---src-pages-dragons-den-exclusive-offer-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fine-jewelry-landing-js": () => import("./../../../src/pages/fine-jewelry-landing.js" /* webpackChunkName: "component---src-pages-fine-jewelry-landing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-london-store-js": () => import("./../../../src/pages/london-store.js" /* webpackChunkName: "component---src-pages-london-store-js" */),
  "component---src-pages-los-angeles-trunkshow-js": () => import("./../../../src/pages/los-angeles-trunkshow.js" /* webpackChunkName: "component---src-pages-los-angeles-trunkshow-js" */),
  "component---src-pages-new-york-trunkshow-js": () => import("./../../../src/pages/new-york-trunkshow.js" /* webpackChunkName: "component---src-pages-new-york-trunkshow-js" */),
  "component---src-pages-paris-store-js": () => import("./../../../src/pages/paris-store.js" /* webpackChunkName: "component---src-pages-paris-store-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-second-life-booking-js": () => import("./../../../src/pages/second-life/booking.js" /* webpackChunkName: "component---src-pages-second-life-booking-js" */),
  "component---src-pages-second-life-our-service-js": () => import("./../../../src/pages/second-life/our-service.js" /* webpackChunkName: "component---src-pages-second-life-our-service-js" */),
  "component---src-pages-shop-jewelry-js": () => import("./../../../src/pages/shop-jewelry.js" /* webpackChunkName: "component---src-pages-shop-jewelry-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-toronto-trunkshow-js": () => import("./../../../src/pages/toronto-trunkshow.js" /* webpackChunkName: "component---src-pages-toronto-trunkshow-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-engagement-js": () => import("./../../../src/templates/engagement.js" /* webpackChunkName: "component---src-templates-engagement-js" */),
  "component---src-templates-gift-card-js": () => import("./../../../src/templates/giftCard.js" /* webpackChunkName: "component---src-templates-gift-card-js" */),
  "component---src-templates-material-js": () => import("./../../../src/templates/material.js" /* webpackChunkName: "component---src-templates-material-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partnership-holiday-js": () => import("./../../../src/templates/partnership/holiday.js" /* webpackChunkName: "component---src-templates-partnership-holiday-js" */),
  "component---src-templates-partnership-partnership-js": () => import("./../../../src/templates/partnership/partnership.js" /* webpackChunkName: "component---src-templates-partnership-partnership-js" */),
  "component---src-templates-partnership-signup-js": () => import("./../../../src/templates/partnership/signup.js" /* webpackChunkName: "component---src-templates-partnership-signup-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

